import Color from 'color';

const backgroundColor = '#141420';
const primaryColor = '#20206a';
const secondaryColor = '#2fa4b0';
const lightGray = '#bdbdbd';
const inputColor = '#1e1e31';
const subtleTextColor = '#3c3c4f';
const overlayColor = Color(inputColor).lighten(1).string();
const inputDarkColor = '#1e1e31';
const radius = '10px';

const DragTypes = {
    SegmentTool: 'sgt',
    DPVTool: 'dpvt',
    BailoutCCRMarker: 'boccr',
    BailoutDPVMarker: 'bodpv',
};

export {
    DragTypes,
    backgroundColor,
    radius,
    primaryColor,
    inputColor,
    overlayColor,
    lightGray,
    inputDarkColor,
    secondaryColor,
    subtleTextColor,
};
