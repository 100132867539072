import React from 'react';
import styled from '@emotion/styled';
import { animated } from '@react-spring/web';
import { backgroundColor, primaryColor, radius } from '../util/constants';

const LEFT_OFFSET = 100;

const Wrapper = styled(animated.div)`
    position: absolute;
    filter: drop-shadow(12px 12px 10px rgba(0, 0, 0, 0.33));
    bottom: 30px;
    width: 300px;

    left: -${LEFT_OFFSET}px;
    z-index: 100;
`;

const Card = styled.div`
    background: ${backgroundColor};
    border: 1px solid ${primaryColor};
    padding: 15px;
    border-radius: ${radius};
    margin-bottom: 0 !important;
`;

const Caret = styled.div`
    width: 28px;
    height: 28px;
    margin-top: -15px;

    transform: rotate(45deg);

    background: ${backgroundColor};
    border-right: 1px solid ${primaryColor};
    border-bottom: 1px solid ${primaryColor};

    margin-left: ${({ offset }) => `${offset + LEFT_OFFSET - 5}px`};
`;

const Tooltip = React.forwardRef(({ children, offset, style }, ref) => {
    return (
        <Wrapper ref={ref} style={style}>
            <Card>{children}</Card>
            <Caret offset={offset} />
        </Wrapper>
    );
});

export default Tooltip;
