import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useDrop } from 'react-dnd';

import DiveSegment from './DiveSegment';
import DPVSegment from './DPVSegment';
import { radius, DragTypes } from '../util/constants';
import { minDepth, maxDepth, penetration } from '../core/metrics';
import { animated, useSpring } from '@react-spring/web';
import Modal from './Modal';
import LabeledInput from './inputs/LabeledInput';
import Button, { Buttons } from './inputs/Button';
import useAppState from '../util/useAppState';
import DiveSegmentMarker from './DiveSegmentMarker';
import dropSpring from '../util/dropSpring';
import DropMaker from './DropMarker';
import TimelineGrid from './TimelineGrid';
import useInput from '../util/useInput';
import { fieldsEmpty } from './inputs/util';

const Wrapper = styled.div`
    margin: 25px 50px 25px 50px;
    display: flex;
    flex-direction: column;
    flex: 1;

    position: relative;
`;

const Segments = styled(animated.div, {
    shouldForwardProp: (prop) => isPropValid(prop),
})`
    display: flex;
    ${({ flex }) => `flex: ${flex}`};

    ${({ centered }) => centered && `display: flex`};
    ${({ centered }) => centered && `align-items: center`};

    position: relative;
    z-index: 1;
`;

const Placeholder = styled(animated.div)`
    display: flex;
    flex: 1;

    border: dashed 4px white;
    border-radius: ${radius};

    align-items: center;
    justify-content: center;

    ${'' /* ${({ dragOver }) => dragOver && `background: red`}; */}
`;

const DropZone = styled(animated.div)`
    width: calc(100% - 7px);
    height: calc(100% - 8px);

    border: dashed 4px white;
    border-radius: ${radius};

    position: absolute;
    z-index: 100;
`;

const Timeline = ({
    diveSegments,
    dpvSegments,
    setDiveSegments,
    setDpvSegments,
    stages,
    dropMarkers,
    setDropMarkers,
}) => {
    const distanceInput = useInput();
    const depthInput = useInput();
    const exitRateInput = useInput();

    const startAtInput = useInput();
    const durationInput = useInput();
    const dpvExitRateInput = useInput();

    const dropMarkerAtInput = useInput();

    const dropMarkerParent = React.useRef();
    const dpvSegmentParent = React.useRef();

    const [newDiveSegmentIndex, setNewDiveSegmentIndex] = React.useState(-1);
    const [editDiveSegmentIndex, setEditDiveSegmentIndex] = React.useState(-1);
    const [dpvSegment, setDpvSegment] = React.useState();
    const [dropMarker, setDropMarker] = React.useState();

    const [nextSegmentId, setNextSegmentId] = useAppState('si', 0);

    const [{ placeholderOver }, placeholderDrop] = useDrop(
        () => ({
            accept: DragTypes.SegmentTool,
            drop: () => {
                setNewDiveSegmentIndex(0);
                focusDiveModal();
            },
            collect: (monitor) => ({
                placeholderOver: !!monitor.isOver(),
            }),
        }),
        [diveSegments, setDiveSegments]
    );

    const [{ canDropDPV }, dpvDropZone] = useDrop(
        () => ({
            accept: DragTypes.DPVTool,
            drop: () => {
                setDpvSegment(-1);
                focusDpvModal();
            },
            collect: (monitor) => ({
                canDropDPV: !!monitor.canDrop(),
            }),
        }),
        [setDpvSegment]
    );

    const [{ canDropMarker }, markerDropZone] = useDrop(
        () => ({
            accept: [DragTypes.BailoutCCRMarker, DragTypes.BailoutDPVMarker],
            drop: (item) => {
                setDropMarker({ type: item.type, action: 'new' });
                focusDropMarkerModal();
            },
            collect: (monitor) => ({
                canDropMarker: !!monitor.canDrop(),
            }),
        }),
        []
    );

    const [dpvDropZoneStyle] = useSpring(
        () => ({
            opacity: canDropDPV ? 1 : 0,
            maxHeight: canDropDPV ? '100%' : '0%',
            borderWidth: canDropDPV ? '4px' : '0px',
        }),
        [canDropDPV]
    );

    const [dpvSegmentsStyle] = useSpring(
        () => ({
            flex: canDropDPV || dpvSegments.length > 0 ? 1 : 0,
            marginBottom: canDropDPV || dpvSegments.length > 0 ? '25px' : '0px',
        }),
        [canDropDPV, dpvSegments]
    );

    const [markersStyle] = useSpring(
        () => ({
            flex: canDropMarker || Object.keys(dropMarkers).length > 0 ? 1 : 0,
        }),
        [canDropMarker]
    );

    const [markerDropZoneStyle] = useSpring(
        () => ({
            opacity: canDropMarker ? 1 : 0,
            maxHeight: canDropMarker ? '100%' : '0%',
        }),
        [canDropMarker]
    );

    const [dropAnimation] = useSpring(dropSpring(true, canDropDPV || canDropMarker || placeholderOver), [
        canDropDPV,
        canDropMarker,
        placeholderOver,
    ]);

    const saveDiveSegment = () => {
        const newDiveSegment = {
            depth: parseFloat(depthInput.value),
            distance: parseFloat(distanceInput.value),
            exitRate: parseFloat(exitRateInput.value),
            id: nextSegmentId,
        };
        let newDiveSegments = [...diveSegments];

        if (newDiveSegmentIndex >= 0) {
            newDiveSegments.splice(newDiveSegmentIndex, 0, newDiveSegment);
        } else if (editDiveSegmentIndex >= 0) {
            newDiveSegments[editDiveSegmentIndex] = newDiveSegment;
        }

        setDiveSegments(newDiveSegments);
        closeModal();
        setNextSegmentId((id) => id + 1);
    };

    const saveDpvSegment = () => {
        const newDpvSegment = {
            startAt: parseFloat(startAtInput.value),
            duration: parseFloat(durationInput.value),
            exitRate: parseFloat(dpvExitRateInput.value),
        };

        let newDpvSegments = [...dpvSegments];

        if (dpvSegment === -1) {
            newDpvSegments.push(newDpvSegment);
        } else if (dpvSegment >= 0) {
            newDpvSegments[dpvSegment] = newDpvSegment;
        }

        setDpvSegments(newDpvSegments);
        closeModal();
    };

    const saveDropMarker = () => {
        const newDropMarkers = {
            ...dropMarkers,
            [dropMarker.type]: { at: parseFloat(dropMarkerAtInput.value) },
        };

        setDropMarkers(newDropMarkers);
        closeModal();
    };

    const diveSegmentOffset = (segment) => {
        const footToPercent = (maxDepth(diveSegments) - minDepth(diveSegments)) / 90;
        return (segment.depth - minDepth(diveSegments)) / footToPercent;
    };

    const diveSegmentModalTitle = () => {
        if (newDiveSegmentIndex >= 0) {
            return 'Add Dive Segment';
        } else if (editDiveSegmentIndex >= 0) {
            return 'Edit Dive Segment';
        }
    };

    const dpvSegmentModalTitle = () => {
        if (dpvSegment === -1) {
            return 'Add DPV Segment';
        } else if (dpvSegment > -1) {
            return 'Edit DPV Segment';
        }
    };

    const dropMarkerModalTitle = () => {
        let title;

        if (dropMarker?.action === 'new') {
            title = 'New';
        } else {
            title = 'Edit';
        }

        if (dropMarker?.type === DragTypes.BailoutCCRMarker) {
            title += ' Bailout CCR';
        } else {
            title += ' Bailout DPV';
        }

        return title;
    };

    const closeModal = () => {
        setNewDiveSegmentIndex(-1);
        setEditDiveSegmentIndex(-1);
        setDpvSegment(undefined);
        setDropMarker();

        depthInput.setValue('');
        distanceInput.setValue('');
        exitRateInput.setValue('');

        startAtInput.setValue('');
        durationInput.setValue('');
        dpvExitRateInput.setValue('');

        dropMarkerAtInput.setValue('');
    };

    const editDiveSegment = (index) => {
        focusDiveModal();
        setEditDiveSegmentIndex(index);
        depthInput.setValue(diveSegments[index].depth);
        distanceInput.setValue(diveSegments[index].distance);
        exitRateInput.setValue(diveSegments[index].exitRate);
    };

    const editDpvSegment = (index) => {
        focusDpvModal();
        setDpvSegment(index);

        startAtInput.setValue(Math.ceil(dpvSegments[index].startAt));
        durationInput.setValue(Math.ceil(dpvSegments[index].duration));
        dpvExitRateInput.setValue(Math.ceil(dpvSegments[index].exitRate));
    };

    const editDropMarker = (type) => {
        focusDropMarkerModal();
        setDropMarker({ type: type, action: 'edit' });
        dropMarkerAtInput.setValue(Math.ceil(dropMarkers[type].at));
    };

    const stagesBySegment = stages.reduce((bySegment, stage) => {
        if (!!bySegment[stage.segment.id]) {
            bySegment[stage.segment.id].push(stage);
        } else {
            bySegment[stage.segment.id] = [stage];
        }

        return bySegment;
    }, {});

    const focusDiveModal = () => {
        setTimeout(() => {
            distanceInput.ref.current.focus();
        }, 200);
    };

    const focusDpvModal = () => {
        setTimeout(() => {
            startAtInput.ref.current.focus();
        }, 200);
    };

    const focusDropMarkerModal = () => {
        setTimeout(() => {
            dropMarkerAtInput.ref.current.focus();
        }, 200);
    };

    const updateDPVSegment = (index, startAt, distanceDelta) => {
        const newDpvSegments = [...dpvSegments];
        newDpvSegments[index].startAt = startAt;

        if (distanceDelta) {
            newDpvSegments[index].duration += distanceDelta;
        }

        setDpvSegments(newDpvSegments);
    };

    const maxPenetration = penetration(diveSegments);

    return (
        <>
            <Wrapper>
                {diveSegments.length > 0 && (
                    <Segments centered style={dpvSegmentsStyle} ref={dpvSegmentParent}>
                        <DropZone ref={dpvDropZone} style={{ ...dpvDropZoneStyle, ...dropAnimation }} />
                        <>
                            {dpvSegments.map((segment, i) => (
                                <DPVSegment
                                    key={segment.startAt}
                                    distance={segment.duration}
                                    exitRate={segment.exitRate}
                                    startAt={segment.startAt}
                                    maxPenetration={maxPenetration}
                                    onDoubleClick={() => editDpvSegment(i)}
                                    parentRef={dpvSegmentParent}
                                    onResize={(startAt, distanceDelta) => updateDPVSegment(i, startAt, distanceDelta)}
                                    leftBound={i === 0 ? 0 : dpvSegments[i - 1].startAt + dpvSegments[i - 1].duration}
                                    rightBound={
                                        i === dpvSegments.length - 1 ? maxPenetration : dpvSegments[i + 1].startAt
                                    }
                                />
                            ))}
                        </>
                    </Segments>
                )}

                <Segments flex={4}>
                    {diveSegments.length > 0 ? (
                        <>
                            {diveSegments.map((segment, i) => (
                                <DiveSegment
                                    key={segment.depth}
                                    depth={segment.depth}
                                    distance={segment.distance}
                                    exitRate={segment.exitRate}
                                    last={i === diveSegments.length - 1}
                                    onDropBefore={() => {
                                        focusDiveModal();
                                        setNewDiveSegmentIndex(i);
                                    }}
                                    onDropAfter={() => {
                                        focusDiveModal();
                                        setNewDiveSegmentIndex(i + 1);
                                    }}
                                    depthOffset={diveSegmentOffset(segment)}
                                    onClick={() => editDiveSegment(i)}
                                    hideMarkers={canDropDPV}
                                    penetration={penetration(diveSegments)}
                                >
                                    {(stagesBySegment[segment.id] ?? []).map((stage) => (
                                        <DiveSegmentMarker
                                            stage={stage}
                                            segmentDistance={segment.distance}
                                            diveSegments={diveSegments}
                                            key={stage.segmentRemaining}
                                        />
                                    ))}
                                </DiveSegment>
                            ))}
                        </>
                    ) : (
                        <Placeholder
                            ref={placeholderDrop}
                            dragOver={placeholderOver}
                            style={placeholderOver ? dropAnimation : {}}
                        >
                            <h1>Drag n' Drop Dive Segments Here</h1>
                        </Placeholder>
                    )}
                </Segments>

                <Segments centered style={markersStyle} ref={dropMarkerParent}>
                    <DropZone ref={markerDropZone} style={{ ...markerDropZoneStyle, ...dropAnimation }} />
                    <>
                        {Object.keys(dropMarkers).map((marker) => (
                            <DropMaker
                                key={marker}
                                type={marker}
                                at={dropMarkers[marker].at}
                                maxPenetration={maxPenetration}
                                onDoubleClick={() => editDropMarker(marker)}
                                parentRef={dropMarkerParent}
                                onAtChange={(newAt) => setDropMarkers({ ...dropMarkers, [marker]: { at: newAt } })}
                            />
                        ))}
                    </>
                </Segments>

                {diveSegments.length > 0 && <TimelineGrid maxPenetration={maxPenetration} />}
            </Wrapper>

            <Modal
                title={diveSegmentModalTitle()}
                open={newDiveSegmentIndex >= 0 || editDiveSegmentIndex >= 0}
                onTryExit={() => closeModal()}
            >
                <LabeledInput
                    label='Distance'
                    placeholder='1500'
                    type='number'
                    min={0}
                    max={maxPenetration}
                    unit='ft.'
                    {...distanceInput}
                />
                <LabeledInput label='Depth' placeholder='170' type='number' min={0} unit='ft.' {...depthInput} />
                <LabeledInput
                    label='Exit Rate'
                    placeholder='35'
                    type='number'
                    min={0}
                    unit='ft/min.'
                    help='Your exit rate if swimming, not if using a DPV.'
                    {...exitRateInput}
                />

                <Buttons>
                    <Button onClick={() => closeModal()}>Cancel</Button>
                    <Button
                        primary
                        onClick={saveDiveSegment}
                        disabled={fieldsEmpty(distanceInput, depthInput, exitRateInput)}
                    >
                        Save
                    </Button>
                </Buttons>
            </Modal>

            <Modal title={dpvSegmentModalTitle()} open={dpvSegment !== undefined} onTryExit={() => closeModal()}>
                <LabeledInput label='Start At' placeholder='0' type='number' min={0} unit='ft.' {...startAtInput} />
                <LabeledInput
                    label='Duration'
                    placeholder='1500'
                    type='number'
                    min={0}
                    max={maxPenetration}
                    unit='ft.'
                    {...durationInput}
                />
                <LabeledInput
                    label='Exit Rate'
                    placeholder='75'
                    type='number'
                    min={0}
                    unit='ft/min.'
                    {...dpvExitRateInput}
                />

                <Buttons>
                    <Button onClick={() => closeModal()}>Cancel</Button>
                    <Button
                        primary
                        onClick={saveDpvSegment}
                        disabled={fieldsEmpty(startAtInput, durationInput, dpvExitRateInput)}
                    >
                        Save
                    </Button>
                </Buttons>
            </Modal>

            <Modal title={dropMarkerModalTitle()} open={!!dropMarker} onTryExit={() => closeModal()}>
                <LabeledInput
                    label='Staged At'
                    placeholder='1500'
                    type='number'
                    min={0}
                    max={maxPenetration}
                    unit='ft.'
                    {...dropMarkerAtInput}
                />

                <Buttons>
                    <Button onClick={() => closeModal()}>Cancel</Button>
                    <Button primary onClick={saveDropMarker} disabled={fieldsEmpty(dropMarkerAtInput)}>
                        Save
                    </Button>
                </Buttons>
            </Modal>
        </>
    );
};

export default Timeline;
