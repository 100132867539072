import React from 'react';
import styled from '@emotion/styled';
import Color from 'color';

import { radius, secondaryColor } from '../util/constants';
import ResizeHandle from './ResizeHandle';

const Wrapper = styled.div`
    position: absolute;
    background: ${secondaryColor};

    height: 20px;
    padding: 10px;
    border-radius: ${radius};
    border: solid 1px ${Color(secondaryColor).lighten(0.5).string()};
    user-select: none;

    display: flex;
    width: ${({ distance, maxPenetration }) => `calc(${(distance / maxPenetration) * 100}% - 22px)`};
    left: ${({ startAt, maxPenetration }) => (startAt / maxPenetration) * 100}%;
    align-items: center;
    z-index: 5;

    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'pointer')};
`;

const Duration = styled.span`
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    white-space: pre;

    margin-left: 10px;

    font-weight: bold;
`;

const Distance = styled.span`
    white-space: pre;
`;

const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
`;

const DPVSegment = ({
    distance: _distance,
    exitRate,
    startAt: _startAt,
    onDoubleClick,
    maxPenetration,
    parentRef,
    onResize,
    leftBound,
    rightBound,
}) => {
    const [startAt, setStartAt] = React.useState(_startAt);
    const [distance, setDistance] = React.useState(_distance);
    const [isDragging, setIsDragging] = React.useState(false);

    const duration = Math.ceil(parseFloat(distance) / parseFloat(exitRate));

    React.useEffect(() => {
        setDistance(_distance);
    }, [_distance]);

    React.useEffect(() => {
        setStartAt(_startAt);
    }, [_startAt]);

    const resizeLeft = (delta) => {
        setStartAt(startAt + delta);
        setDistance(distance + delta * -1);
    };

    const onMouseDown = () => {
        const initialAt = startAt;
        const containerWidth = parentRef?.current?.clientWidth ?? 0;

        let movementX = 0;
        let newStartAt;

        setIsDragging(true);

        function onMouseMove(moveEvent) {
            movementX += moveEvent.movementX;
            const distanceDelta = (movementX / containerWidth) * maxPenetration;

            if (initialAt + distanceDelta > leftBound && initialAt + distanceDelta + distance < rightBound) {
                setStartAt(initialAt + distanceDelta);
            }
        }

        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);

            setIsDragging(false);

            if (newStartAt !== undefined) {
                onResize(newStartAt, null);
            }
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp);
    };

    return (
        <>
            <Wrapper
                distance={distance}
                startAt={startAt}
                maxPenetration={maxPenetration}
                onDoubleClick={onDoubleClick}
                onMouseDown={onMouseDown}
                isDragging={isDragging}
            >
                <ResizeHandle
                    left
                    leftBound={leftBound}
                    maxPenetration={maxPenetration}
                    parentRef={parentRef}
                    onResize={resizeLeft}
                    onResizeFinished={(newDistance) => onResize(startAt + newDistance, newDistance * -1)}
                    startAt={startAt}
                    distance={distance}
                />
                <TextWrapper>
                    <Distance>{Math.ceil(distance)}'</Distance>
                    <Duration>{duration} min</Duration>
                </TextWrapper>
                <ResizeHandle
                    right
                    rightBound={rightBound}
                    maxPenetration={maxPenetration}
                    parentRef={parentRef}
                    onResize={(delta) => setDistance(distance + delta)}
                    onResizeFinished={(newDistance) => onResize(startAt, newDistance)}
                    startAt={startAt}
                    distance={distance}
                />
            </Wrapper>
        </>
    );
};

export default DPVSegment;
