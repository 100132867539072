const tanks = {
    LP85: { fill: 2640, cf: 85 },
    LP95: { fill: 2640, cf: 95 },
    LP108: { fill: 2640, cf: 108 },
    LP114: { fill: 2640, cf: 114 },
    HP100: { fill: 3440, cf: 100 },
    AL80: { fill: 3000, cf: 80 },
    AL40: { fill: 3000, cf: 40 },
};

export default tanks;
