import styled from '@emotion/styled';
import PlanMetric from './PlanMetric';
import { exitTime, maxDepth, penetration, gasVolume } from '../core/metrics';
import React from 'react';
import Switch from './inputs/Switch';
import EditableLabel from './inputs/EditableLabel';
import useInput from '../util/useInput';

const Row = styled.div`
    display: flex;

    div {
        margin-right: 15px;

        :last-of-type {
            margin-right: 0;
        }
    }

    margin-bottom: 15px;
    :last-of-type {
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div`
    flex: 1;
    margin-right: 50px;
`;

const Plan = ({
    diveSegments,
    dpvSegments,
    backgas,
    backgasPressure,
    sacRate,
    stages,
    failureMode,
    setFailureMode,
    modelName,
    setModelName,
}) => {
    const backgasDefined = backgas && backgasPressure;
    const modelNameInput = useInput(modelName);

    React.useEffect(() => {
        setModelName(modelNameInput.value);
    }, [modelNameInput.value, setModelName]);

    return (
        <Wrapper>
            <EditableLabel value='Untitled Model' {...modelNameInput} />

            <Row>
                <PlanMetric name='Exit Time' value={exitTime(diveSegments, dpvSegments)} unit='minutes' />
                <PlanMetric name='Penetration' value={diveSegments ? penetration(diveSegments) : 0} unit='feet' />
                <PlanMetric name='Max Depth' value={diveSegments ? maxDepth(diveSegments) : 0} unit='feet' />
            </Row>

            <Row>
                {dpvSegments.length > 0 && (
                    <PlanMetric name='Swim Exit Time' value={exitTime(diveSegments, [])} unit='minutes' />
                )}
                <PlanMetric
                    name='Gas Volume'
                    value={
                        backgasDefined ? gasVolume(diveSegments, stages, backgas, backgasPressure, sacRate) : undefined
                    }
                    unit='cubic feet'
                />
                <PlanMetric name='Stages' value={backgasDefined ? stages.length : undefined} unit="AL80's" />
            </Row>

            <Row>
                <Switch
                    option1='CCR Failure'
                    option2='CCR + DPV Failure'
                    selected={failureMode}
                    onChange={(fm) => setFailureMode(fm)}
                />
            </Row>
        </Wrapper>
    );
};

export default Plan;
