import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 10px;
    cursor: col-resize;

    ${({ left }) => left && `left: -2px;`}
    ${({ right }) => right && `right: -2px;`}
`;

const ResizeHandle = ({
    left,
    right,
    maxPenetration,
    parentRef,
    onResize,
    onResizeFinished,
    startAt,
    distance,
    leftBound,
    rightBound,
}) => {
    const onMouseDown = (event) => {
        const containerWidth = parentRef?.current?.clientWidth ?? 0;

        let movementX = 0;
        let newDistance;

        event.stopPropagation();

        function onMouseMove(moveEvent) {
            movementX += moveEvent.movementX;
            const maybeNewDistance = (movementX / containerWidth) * maxPenetration;

            if (
                (left && maybeNewDistance + startAt > leftBound) ||
                (right && maybeNewDistance + startAt + distance < rightBound)
            ) {
                newDistance = maybeNewDistance;
                if (newDistance !== undefined) {
                    onResize(newDistance);
                }
            }
        }

        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);

            if (newDistance !== undefined) {
                onResizeFinished(newDistance);
            }
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp);
    };

    return <Wrapper left={left} right={right} onMouseDown={onMouseDown} />;
};

export default ResizeHandle;
