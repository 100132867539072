import React from 'react';
import useInput from '../../util/useInput';
import Button, { Buttons } from '../inputs/Button';
import LabeledInput from '../inputs/LabeledInput';
import LabeledPicker from '../inputs/LabeledPicker';
import Modal from '../Modal';
import Toast, { useToast } from '../Toast';
import { fieldsEmpty } from '../inputs/util';
import LabledCheckbox from '../inputs/LabeledCheck';

const ReportIssue = ({ open, onTryExit }) => {
    const [submitToast, toast] = useToast();
    const [submitMessage, setSubmitMessage] = React.useState();
    const [reportType, setReportType] = React.useState('Invalid Output');
    const reporter = useInput();
    const request = useInput();
    const issue = useInput();
    const expected = useInput();
    const includeModel = useInput(true, { check: true });

    const submitReport = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => setSubmitMessage('Report sent, thanks!'))
            .catch(() => setSubmitMessage('Failed to send report, try again?'));

        toast();
        onTryExit();

        e.target.reset();
    };

    React.useEffect(() => {
        if (open) {
            setReportType('Invalid Output');
            setTimeout(() => {
                reporter.ref.current?.focus();
            }, 200);
        }
    }, [open, reporter.ref]);

    const isFeatureRequest = () => {
        return reportType === 'Feature Request';
    };

    return (
        <>
            <Modal title='Report Issue' open={open} onTryExit={onTryExit}>
                <form
                    name={isFeatureRequest() ? 'Feature Request' : 'Issue Report'}
                    onSubmit={submitReport}
                    netlify='true'
                >
                    <LabeledInput
                        label='Your Email'
                        name='email'
                        type='email'
                        placeholder='you@email.com'
                        {...reporter}
                    />
                    <LabeledPicker
                        label='Report Type'
                        options={['Invalid Output', 'Visual Issue', 'Feature Request']}
                        value={reportType}
                        onChange={(newType) => setReportType(newType)}
                    />
                    {isFeatureRequest() ? (
                        <>
                            <LabeledInput multiline label='Request' placeholder='What should change?' {...request} />
                        </>
                    ) : (
                        <>
                            <LabeledInput multiline label='Issue' placeholder='What went wrong?' {...issue} />
                            <LabeledInput
                                multiline
                                label='Expected Behavior'
                                name='expected'
                                placeholder='What should have happened?'
                                {...expected}
                            />
                        </>
                    )}

                    <LabledCheckbox label='Include my dive model with report' {...includeModel} />

                    <input
                        type='hidden'
                        name='form-name'
                        value={isFeatureRequest() ? 'Feature Request' : 'Issue Report'}
                    />
                    <input type='hidden' name='url' value={includeModel.checked ? window.location.href : ''} />
                    <input type='hidden' name='build' value={process.env.REACT_APP_GIT_SHA} />

                    <Buttons>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                onTryExit();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            primary
                            onClick={() => {}}
                            disabled={
                                isFeatureRequest()
                                    ? fieldsEmpty(reporter, request)
                                    : fieldsEmpty(reporter, issue, expected)
                            }
                            type='submit'
                        >
                            Report
                        </Button>
                    </Buttons>
                </form>
            </Modal>

            <Toast toast={submitToast}>{submitMessage}</Toast>
        </>
    );
};

export default ReportIssue;
