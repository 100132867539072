import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, primaryColor } from '../util/constants';
import Toast, { useToast } from './Toast';
import ReportIssue from './modals/ReportIssue';
import RecentModels from './modals/RecentModels';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    margin-left: 50px;
    margin-right: 50px;

    background: ${backgroundColor};
    z-index: 5;
`;

const Title = styled.h1`
    display: flex;
    align-items: center;

    img {
        height: 40px;
        margin-right: 15px;
    }
`;

const ActionLink = styled.a`
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 4px;
    color: white;
    text-decoration: none;

    :hover {
        background: white;
        color: ${primaryColor};
    }
`;

const ActionLinks = styled.nav`
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;

    a {
        margin-right: 25px;

        :first-of-type {
            margin-right: 0;
        }
    }
`;

const Logo = () => {
    return (
        <Title>
            <img src='/soloist.svg' alt='Soloist' />
            soloist.
        </Title>
    );
};

const Header = ({ modelId }) => {
    const [shareToast, toast] = useToast();
    const [toastText, setToastText] = React.useState('');
    const [reportIssueOpen, setReportIssueOpen] = React.useState(false);
    const [recentModelsOpen, setRecentModelsOpen] = React.useState(false);

    const share = () => {
        if (window.location.search === '') {
            setToastText("Can't share an empty dive!");
            toast();

            return;
        }

        fetch(`/.netlify/functions/share?qs=${encodeURIComponent(window.location.search)}`)
            .then((res) => res.json())
            .then((res) => {
                setToastText('Link Copied!');
                navigator.clipboard.writeText(res.url);
                toast();
            })
            .catch(() => {
                setToastText('Failed to share, try again?');
                toast();
            });
    };

    return (
        <Wrapper>
            <Logo />

            <ActionLinks>
                <ActionLink href='/' target='_blank'>
                    New Dive
                </ActionLink>
                <ActionLink onClick={share}>Share</ActionLink>
                <ActionLink onClick={() => setRecentModelsOpen(true)}>Recent Models</ActionLink>
                <ActionLink onClick={() => setReportIssueOpen(true)}>Report Issue</ActionLink>
            </ActionLinks>

            <ReportIssue open={reportIssueOpen} onTryExit={() => setReportIssueOpen(false)} />
            <RecentModels open={recentModelsOpen} onTryExit={() => setRecentModelsOpen(false)} modelId={modelId} />

            <Toast toast={shareToast}>{toastText}</Toast>
        </Wrapper>
    );
};

export default Header;
export { Logo };
