import React from 'react';

const decodeParameter = (param) => {
    const params = new URLSearchParams(window.location.search);

    if (params.get(param)) {
        const raw = atob(decodeURIComponent(params.get(param)));

        if (raw === 'undefined') {
            return undefined;
        }

        return JSON.parse(raw);
    }

    return null;
};

const useAppState = (key, defaultValue, forceSetDefault) => {
    const decodedKey = decodeParameter(key);
    const initialState = decodedKey ?? defaultValue;
    const [internalState, setInternalState] = React.useState(initialState);

    const stateUpdater = (newState) => {
        setInternalState(newState);

        let serialized;

        if (typeof newState === 'function') {
            serialized = btoa(JSON.stringify(newState(internalState)));
        } else {
            serialized = btoa(JSON.stringify(newState));
        }

        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set(key, serialized);
        window.history.pushState(null, null, newUrl);
    };

    if (forceSetDefault && !decodedKey) {
        stateUpdater(defaultValue);
    }

    return [internalState, stateUpdater];
};

export default useAppState;
