import React from 'react';

const useInput = (initialValue, options) => {
    const [value, setValue] = React.useState(initialValue ?? '');
    const ref = React.useRef();

    const onChange = (e) => {
        if (options?.check) {
            setValue(e?.target?.checked);
        } else {
            setValue(e?.target?.value ?? e);
        }
    };

    if (options?.check) {
        return { checked: value, onChange, setValue, ref };
    } else {
        return { value, onChange, setValue, ref };
    }
};

export default useInput;
