import React from 'react';
import styled from '@emotion/styled';
import { inputColor, radius } from '../../util/constants';
import Color from 'color';
import { animated, useSpring } from '@react-spring/web';

const Wrapper = styled.div`
    position: relative;
    background: ${inputColor};
    border-radius: ${radius};

    flex: 1;

    display: flex;

    cursor: pointer;
`;

const Option = styled.div`
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 25px;

    z-index: 1;
`;

const SelectorWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    width: calc(100% - 20px);
    height: calc(100% - 20px);

    padding: 10px;
`;

const Selector = styled(animated.div)`
    background: ${Color(inputColor).lighten(1).string()};
    border-radius: 5px;
    height: 100%;
    width: 50%;

    position: relative;
`;

const Switch = ({ option1, option2, onChange, selected }) => {
    const [hover, setHover] = React.useState();

    const [selectorStyle] = useSpring(
        () => ({
            left: selected === 1 ? '0%' : '50%',
            transform: `translateX(${hover === 1 ? '-10px' : hover === 2 ? '10px' : '0px'})`,
        }),
        [selected, hover]
    );

    const changeOption = (newOption) => {
        onChange(newOption);
        setHover();
    };

    return (
        <Wrapper>
            <SelectorWrapper>
                <Selector style={selectorStyle} />
            </SelectorWrapper>

            <Option
                onClick={() => changeOption(1)}
                onMouseEnter={() => selected !== 1 && setHover(1)}
                onMouseLeave={() => setHover()}
            >
                {option1}
            </Option>

            <Option
                onClick={() => changeOption(2)}
                onMouseEnter={() => selected !== 2 && setHover(2)}
                onMouseLeave={() => setHover()}
            >
                {option2}
            </Option>
        </Wrapper>
    );
};

export default Switch;
