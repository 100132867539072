import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import useAppState from '../util/useAppState';
import { backgroundColor } from '../util/constants';
import stageDrops from '../core/stageDrops';
import postFailureDpvs from '../core/postFailureDpvs';
import Timeline from './Timeline';
import Toolbar from './Toolbar';
import Plan from './Plan';
import Configuration from './Configuration';
import Header from './Header';
import Welcome from './Welcome';
import MobileSplash from './MobileSplash';
import Footer from './Footer';
import pseudoRandomId from '../util/id';
import { maxDepth, penetration } from '../core/metrics';

const Wrapper = styled.div`
    background: ${backgroundColor};
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell,
        Ubuntu, roboto, noto, arial, sans-serif;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;

    overflow: hidden;
`;

const WorkspaceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const LowerHalf = styled.div`
    display: flex;
    flex: 1;
    margin: 25px 50px;

    h3 {
        margin-top: 0;
    }
`;

const Content = styled.div`
    display: flex;
    height: 100%;
`;

const Separator = styled.div`
    background: #3c3c4f;
    opacity: 0.75;
    height: 1px;
    margin-right: 50px;
    margin-left: 50px;
`;

const Soloist = () => {
    const [diveSegments, setDiveSegments] = useAppState('ds', []);
    const [dpvSegments, setDpvSegments] = useAppState('dpvs', []);
    const [backgasPressure, setBackgasPressure] = useAppState('bgp');
    const [backgas, setBackgas] = useAppState('bg', 'LP85');
    const [backgasHoldout, setBackgasHoldout] = useAppState('bgh');
    const [stageOverdrop, setStageOverdrop] = useAppState('so');
    const [sacRate, setSacRate] = useAppState('sac');
    const [failureMode, setFailureMode] = useAppState('fm', 1);
    const [dropMarkers, setDropMarkers] = useAppState('dm', {});
    const [modelName, setModelName] = useAppState('mn', 'Untitled Model');
    const [modelId] = useAppState('mi', pseudoRandomId(), true);

    const [stages, setStages] = React.useState([]);

    React.useEffect(() => {
        if (diveSegments && sacRate && backgas && backgasPressure) {
            setTimeout(() => {
                setStages(
                    stageDrops(
                        diveSegments,
                        sacRate,
                        backgas,
                        backgasPressure,
                        backgasHoldout,
                        stageOverdrop,
                        postFailureDpvs(dpvSegments, dropMarkers, failureMode),
                        dropMarkers
                    )
                );
            }, 400);
        }
    }, [
        diveSegments,
        backgas,
        backgasPressure,
        stageOverdrop,
        sacRate,
        backgasHoldout,
        dpvSegments,
        dropMarkers,
        failureMode,
    ]);

    React.useEffect(() => {
        const recentModels = JSON.parse(localStorage.getItem('recentModels') ?? '{}');
        recentModels[modelId] = {
            name: modelName,
            penetration: penetration(diveSegments),
            maxDepth: maxDepth(diveSegments),
            url: window.location.href,
        };
        localStorage.setItem('recentModels', JSON.stringify(recentModels));

        window.name = modelName;
    }, [modelId, modelName, diveSegments]);

    return (
        <Wrapper>
            <Helmet>
                <title>Soloist - {modelName}</title>
            </Helmet>
            <MobileSplash />
            <Welcome />
            <Header modelId={modelId} />
            <Content>
                <Toolbar diveSegments={diveSegments} />
                <WorkspaceWrapper>
                    <Timeline
                        diveSegments={diveSegments}
                        dpvSegments={dpvSegments}
                        setDpvSegments={setDpvSegments}
                        setDiveSegments={setDiveSegments}
                        stages={stages}
                        dropMarkers={dropMarkers}
                        setDropMarkers={setDropMarkers}
                    />
                    <Separator />
                    <LowerHalf>
                        <Plan
                            diveSegments={diveSegments}
                            dpvSegments={dpvSegments}
                            backgas={backgas}
                            backgasPressure={backgasPressure}
                            sacRate={sacRate}
                            stageOverdrop={stageOverdrop}
                            stages={stages}
                            failureMode={failureMode}
                            setFailureMode={setFailureMode}
                            modelName={modelName}
                            setModelName={setModelName}
                        />

                        <Configuration
                            backgasPressure={backgasPressure}
                            setBackgasPressure={setBackgasPressure}
                            backgas={backgas}
                            setBackgas={setBackgas}
                            backgasHoldout={backgasHoldout}
                            setBackgasHoldout={setBackgasHoldout}
                            stageOverdrop={stageOverdrop}
                            setStageOverdrop={setStageOverdrop}
                            sacRate={sacRate}
                            setSacRate={setSacRate}
                        />
                    </LowerHalf>
                </WorkspaceWrapper>
            </Content>
            <Footer />
        </Wrapper>
    );
};

export default Soloist;
