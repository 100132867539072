import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, DragTypes, radius } from '../util/constants';

const Wrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: ${({ at, maxPenetration }) => (at / maxPenetration) * 100}%;
    user-select: none;
    z-index: 1;
`;

const Marker = styled.div`
    font-weight: bold;
    color: ${backgroundColor};
    background: white;
    border-radius: ${radius};
    padding: 10px;
    font-size: 10px;

    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'pointer')};
`;

const Line = styled.div`
    width: 1px;
    height: 1000px;
    background: white;
    opacity: 0.25;
    margin-bottom: 5px;
`;

const DropMaker = ({ type, at, maxPenetration, onDoubleClick, parentRef, onAtChange }) => {
    const [currentAt, setCurrentAt] = React.useState(at);
    const [isDragging, setIsDragging] = React.useState(false);

    React.useEffect(() => {
        setCurrentAt(at);
    }, [at]);

    const onMouseDown = () => {
        const initialAt = currentAt;
        const containerWidth = parentRef?.current?.clientWidth ?? 0;

        let movementX = 0;
        let newDistance;

        setIsDragging(true);

        function onMouseMove(moveEvent) {
            movementX += moveEvent.movementX;

            const distanceDelta = (movementX / containerWidth) * maxPenetration;
            newDistance = Math.min(Math.max(initialAt + distanceDelta, 0), maxPenetration);
            setCurrentAt(newDistance);
        }

        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);

            setIsDragging(false);

            if (newDistance !== undefined) {
                onAtChange(newDistance);
            }
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp);
    };

    return (
        <Wrapper at={currentAt} maxPenetration={maxPenetration} onDoubleClick={onDoubleClick} onMouseDown={onMouseDown}>
            <Line />

            <Marker isDragging={isDragging}>
                {type === DragTypes.BailoutCCRMarker ? 'Bailout CCR' : 'Bailout DPV'}
            </Marker>
        </Wrapper>
    );
};

export default DropMaker;
