import styled from '@emotion/styled';
import Color from 'color';
import { inputColor, primaryColor } from '../../util/constants';

const Button = styled.button`
    outline: none;
    border: none;

    color: white;
    font-size: 16px;
    padding: 12px;

    border-radius: 5px;

    background: ${inputColor};
    ${({ primary }) => primary && `background: ${primaryColor}`};

    cursor: pointer;

    :disabled {
        background: ${Color(inputColor).darken(0.5).string()};
        ${({ primary }) => primary && `background: ${Color(primaryColor).darken(0.5).string()}`};

        cursor: not-allowed;
    }
`;

const Buttons = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;

    button {
        margin-right: 15px;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export default Button;
export { Buttons };
