import sliceDiveStages from './sliceDiveStages';
import tanks from './tanks';

const FailureModes = {
    CCR: 1,
    CCR_DPV: 2,
};

const exitTime = (diveSegments, dpvSegments) => {
    if (diveSegments.length === 0) {
        return 0;
    }

    return sliceDiveStages(diveSegments, dpvSegments).reduce(
        (time, segment) => time + Math.ceil(parseFloat(segment.distance) / parseFloat(segment.exitRate)),
        0
    );
};

const penetration = (diveSegments) => {
    return diveSegments.reduce((distance, segment) => distance + segment.distance, 0);
};

const maxDepth = (diveSegments) => {
    return diveSegments.reduce((max, segment) => Math.max(max, segment.depth), 0);
};

const minDepth = (diveSegments) => {
    return diveSegments.reduce((min, segment) => Math.min(min, segment.depth), maxDepth(diveSegments));
};

const backgasVolume = (backgas, backgasPressure, backgasHoldout) => {
    let backgasTank = tanks[backgas];
    if (!backgasTank) return 0;

    return (((backgasPressure - (backgasHoldout ?? 0)) * backgasTank.cf) / backgasTank.fill) * 2;
};

const gasVolume = (diveSegments, stages, backgas, backgasPressure, sacRate) => {
    if (!sacRate || !backgasPressure) return undefined;

    let consumedBackgas = backgasVolume(backgas, backgasPressure);
    const stageVolume = stages.reduce((volume, stage) => {
        const stageUsed = stage.remaining === 80 ? 80 : 80 - stage.remaining;
        return volume + stageUsed;
    }, 0);

    if (stages.length === 0) {
        consumedBackgas = diveSegments.reduce((requiredGas, segment) => {
            const atm = segment.depth / 33 + 1;
            const rmv = atm * sacRate;
            const duration = segment.distance / segment.exitRate;
            return requiredGas + rmv * duration;
        }, 0);
    }

    return Math.ceil(consumedBackgas + stageVolume);
};

export { exitTime, penetration, maxDepth, minDepth, gasVolume, backgasVolume, FailureModes };
