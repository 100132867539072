import React from 'react';
import Modal from '../Modal';
import styled from '@emotion/styled';
import { inputColor, subtleTextColor } from '../../util/constants';
import { animated, useSpring } from '@react-spring/web';

const Table = styled.div`
    overflow: scroll;
    max-height: 500px;
    padding-bottom: 40px;
    width: 500px;
`;

const Row = styled.div`
    display: flex;

    :hover {
        background: ${inputColor};
    }

    transition: all 0.2s;

    cursor: pointer;

    padding: 25px 40px;
`;

const RowBadge = styled.div`
    font-size: 12px;
    font-weight: light;
`;

const RowDescription = styled.p`
    margin: 0;
`;

const RowContent = styled.div`
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
`;

const RowLink = styled.a`
    text-decoration: none;

    :visited {
        color: white;
    }

    :active {
        color: white;
    }

    :link {
        color: white;
    }
`;

const BleedToEdgeModal = styled(Modal)`
    padding: 0;

    width: 500px;

    h2 {
        padding-left: 40px;
        padding-top: 40px;
    }
`;

const Delete = styled(animated.button)`
    outline: none;
    border: none;
    margin: 0;
    padding: 0;

    border-radius: 100px;
    height: 25px;
    width: 25px;
    color: ${inputColor};

    background: white;
`;

const RowRight = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row-reverse;
`;

const Placeholder = styled.p`
    padding: 40px;
    color: ${subtleTextColor};
`;

const RecentModel = ({ url, name, maxDepth, penetration, id, modelId, onDelete }) => {
    const [hovering, setHovering] = React.useState(false);
    const [hoverSpring] = useSpring(
        () => ({
            opacity: hovering ? 1 : 0,
        }),
        [hovering]
    );

    return (
        <RowLink
            href={id === modelId ? '#' : url}
            target={name}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Row>
                <RowContent>
                    <div>
                        <b>{name}</b>
                        <RowDescription>
                            {penetration}' penetration to {maxDepth}' of depth
                        </RowDescription>
                    </div>
                </RowContent>

                <RowRight>
                    {id === modelId ? (
                        <RowBadge>Viewing</RowBadge>
                    ) : (
                        <Delete style={hoverSpring} onClick={onDelete}>
                            ✖
                        </Delete>
                    )}
                </RowRight>
            </Row>
        </RowLink>
    );
};

const RecentModels = ({ open, onTryExit, modelId }) => {
    const [recentModels, setRecentModels] = React.useState(JSON.parse(localStorage.getItem('recentModels') ?? '{}'));

    const deleteModel = (e, deletedId) => {
        e.stopPropagation();

        const newModels = { ...recentModels };
        delete newModels[deletedId];

        setRecentModels(newModels);
        localStorage.setItem('recentModels', JSON.stringify(newModels));
    };

    return (
        <BleedToEdgeModal title='Recent Models' open={open} onTryExit={onTryExit}>
            {Object.keys(recentModels).length > 0 ? (
                <Table>
                    {Object.keys(recentModels).map((id) => (
                        <RecentModel
                            id={id}
                            key={id}
                            modelId={modelId}
                            onDelete={(e) => deleteModel(e, id)}
                            {...recentModels[id]}
                        />
                    ))}
                </Table>
            ) : (
                <Placeholder>Looks like you haven't worked on any models recently.</Placeholder>
            )}
        </BleedToEdgeModal>
    );
};

export default RecentModels;
