import React from 'react';
import styled from '@emotion/styled';
import Label from './Label';
import { inputColor, primaryColor } from '../../util/constants';

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        margin: 0;
    }
`;

const Checkbox = styled.input`
    appearance: none;
    position: relative;

    :checked {
        :after {
            content: '✔';
            font-size: 16px;
            position: absolute;
            top: 3px;
            left: 5px;
            color: #99a1a7;
        }
    }

    :hover {
        border: solid 1px ${primaryColor};
    }

    border: solid 1px ${inputColor};

    background: ${inputColor};
    border-radius: 3px;

    height: 25px;
    width: 25px;

    transition: all 0.2s;

    cursor: pointer;
`;

const LabledCheckbox = React.forwardRef(({ label, ...props }, ref) => {
    return (
        <Wrapper>
            <Label>{label}</Label>
            <Checkbox type='checkbox' {...props} ref={ref} />
        </Wrapper>
    );
});

export default LabledCheckbox;
