import { penetration } from './metrics';
import { DragTypes } from '../util/constants';

const sliceDiveStages = (diveSegments, dpvSegments, dropMarkers = {}) => {
    const segments = [...diveSegments];
    const dpvs = [...dpvSegments];

    if (segments.length === 0) {
        return [];
    }

    const virtualSegments = [];

    let currentDpv = dpvs.pop();
    let currentSegment = segments.pop();

    let currentPenetration = penetration(diveSegments);
    let segmentRemaining = currentSegment.distance;

    while (currentSegment) {
        let distanceToDpv = undefined;

        if (currentDpv) {
            distanceToDpv = currentPenetration - (currentDpv.startAt + currentDpv.duration);
        }

        if (distanceToDpv === 0) {
            // We're at the start of a DPV segment
            if (currentDpv.duration <= segmentRemaining) {
                virtualSegments.push({
                    realSegment: currentSegment,
                    distance: currentDpv.duration,
                    exitRate: currentDpv.exitRate,
                    needsStages: true,
                });
                currentPenetration -= currentDpv.duration;
                segmentRemaining -= currentDpv.duration;
                currentDpv = dpvs.pop();
            } else {
                virtualSegments.push({
                    realSegment: currentSegment,
                    distance: segmentRemaining,
                    exitRate: currentDpv.exitRate,
                    needsStages: true,
                });
                currentPenetration -= segmentRemaining;
                currentSegment = segments.pop();
                segmentRemaining = currentSegment?.distance;
            }
        } else if (distanceToDpv < 0) {
            // We're already in a DPV segment
            const overlap = penetration(segments) + segmentRemaining - currentDpv.startAt;

            virtualSegments.push({
                realSegment: currentSegment,
                // We might be overlapping a whole segment or a single part of one
                distance: Math.min(overlap, segmentRemaining),
                exitRate: currentDpv.exitRate,
                needsStages: true,
            });

            if (overlap >= currentSegment.distance) {
                currentPenetration -= segmentRemaining;
                currentSegment = segments.pop();
                segmentRemaining = currentSegment?.distance;
            } else {
                currentDpv = dpvs.pop();
                currentPenetration -= overlap;
                segmentRemaining -= overlap;
            }
        } else if (!distanceToDpv || currentSegment.distance < distanceToDpv) {
            // DPV is not in this real segment
            virtualSegments.push({
                realSegment: currentSegment,
                distance: segmentRemaining,
                exitRate: currentSegment.exitRate,
                needsStages: true,
            });
            currentPenetration -= segmentRemaining;
            currentSegment = segments.pop();
            segmentRemaining = currentSegment?.distance;
        } else if (currentSegment.distance >= distanceToDpv) {
            // DPV is in this real segment
            virtualSegments.push({
                realSegment: currentSegment,
                distance: distanceToDpv,
                exitRate: currentSegment.exitRate,
                needsStages: true,
            });
            currentPenetration -= distanceToDpv;
            segmentRemaining -= distanceToDpv;
        }

        if (segmentRemaining === 0) {
            currentSegment = segments.pop();
            segmentRemaining = currentSegment?.distance;
        }
    }

    virtualSegments.reverse();

    let currentSegmentId = virtualSegments[0].realSegment.id;
    let currentOffset = 0;

    for (let segment of virtualSegments) {
        if (segment.realSegment.id === currentSegmentId) {
            segment.offset = currentOffset;
            currentOffset += segment.distance;
        } else {
            currentOffset = 0;
            currentSegmentId = segment.realSegment.id;
            segment.offset = 0;
            currentOffset += segment.distance;
        }
    }

    virtualSegments.reverse();

    const bailoutCcrAt = dropMarkers[DragTypes.BailoutCCRMarker]?.at;

    if (bailoutCcrAt) {
        let currentSegmentEnd = penetration(virtualSegments);
        let newSegment;
        let newSegmentIndex;

        for (let i = 0; i < virtualSegments.length; i++) {
            const currentSegment = virtualSegments[i];

            if (bailoutCcrAt >= currentSegmentEnd) {
                currentSegment.needsStages = false;
            } else if (bailoutCcrAt > currentSegmentEnd - currentSegment.distance && bailoutCcrAt < currentSegmentEnd) {
                const stagesNeededFor = currentSegmentEnd - bailoutCcrAt;
                const stagesNotNeededFor = currentSegment.distance - stagesNeededFor;

                newSegment = {
                    ...currentSegment,
                    distance: stagesNotNeededFor,
                    needsStages: false,
                };
                newSegmentIndex = Math.max(i + 1, 0);

                currentSegment.distance = stagesNeededFor;
                currentSegment.offset += stagesNotNeededFor;
            }

            currentSegmentEnd -= currentSegment.distance;
        }

        if (newSegment) {
            virtualSegments.splice(newSegmentIndex, 0, newSegment);
        }
    }

    return virtualSegments;
};

export default sliceDiveStages;
