import React from 'react';
import styled from '@emotion/styled';
import { inputColor, primaryColor } from '../../util/constants';
import Label from './Label';
import Help from './Help';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
`;

const Select = styled.select`
    border: solid 1px ${inputColor};
    background: ${inputColor};
    border-radius: 5px;
    padding: 13px;
    font-size: 16px;
    outline: none;

    color: white;

    transition: border 0.25s;
    cursor: pointer;

    :hover {
        border: solid 1px ${primaryColor};
    }
`;

const LabeledPicker = React.forwardRef(({ label, options, onChange, value, help, name }, ref) => {
    return (
        <Wrapper>
            <Label>
                {label}
                {help && <Help>{help}</Help>}
            </Label>
            <Select
                ref={ref}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                name={name || label.toLowerCase()}
            >
                {options.map((option) => (
                    <option value={option} key={option}>
                        {option}
                    </option>
                ))}
            </Select>
        </Wrapper>
    );
});

export default LabeledPicker;
