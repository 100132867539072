import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, radius } from '../util/constants';
import { distanceToSegment } from '../core/utils';

const Wrapper = styled.div`
    position: absolute;
    ${({ offset }) => `left: ${offset}%`};

    display: flex;
    flex-direction: column;
    justify-content: center;
    top: -45px;
`;

const Line = styled.div`
    width: 1px;
    height: 15px;
    background: white;
`;

const Marker = styled.div`
    color: ${backgroundColor};
    background: white;
    border-radius: ${radius};
    width: max-content;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 5px;
    font-size: 10px;
`;

const DiveSegmentMarker = ({ stage, segmentDistance, diveSegments }) => {
    const distance = Math.ceil(stage.segmentRemaining + distanceToSegment(diveSegments, stage.segment));

    return (
        <Wrapper offset={(stage.segmentRemaining / segmentDistance) * 100}>
            <Marker>
                <b>Drop</b>&nbsp;@ {distance} &nbsp; <b>ft.</b>
            </Marker>

            <Line />
        </Wrapper>
    );
};

export default DiveSegmentMarker;
