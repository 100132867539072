import React from 'react';

const SVG = ({ src, className }) => {
    const [svgData, setSvgData] = React.useState('');

    React.useEffect(() => {
        fetch(src)
            .then((response) => response.text())
            .then((data) => setSvgData(data));
    }, [src]);

    return <div className={className} dangerouslySetInnerHTML={{ __html: svgData }} />;
};

export default SVG;
