const distanceToSegment = (diveSegments, toSegment) => {
    let totalDistance = 0;

    for (let segment of diveSegments) {
        if (segment.id === toSegment.id) {
            return totalDistance;
        }

        totalDistance += segment.distance;
    }
};

export { distanceToSegment };
