import React from 'react';
import styled from '@emotion/styled';
import { subtleTextColor } from '../util/constants';

const Wrapper = styled.footer`
    display: flex;

    position: absolute;
    bottom: 0;
    right: 0;

    padding: 25px;
    padding-right: 50px;
    font-size: 10px;

    color: ${subtleTextColor};
`;

const Footer = () => {
    return (
        <Wrapper>
            Made with ♥ in Gainesville, Florida. Build {process.env.REACT_APP_GIT_SHA.substring(0, 6)}, released on{' '}
            {process.env.REACT_APP_BUILD_DATE}.
        </Wrapper>
    );
};

export default Footer;
