import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from '@react-spring/web';

import { backgroundColor, radius, inputColor } from '../util/constants';

const Wrapper = styled(animated.div)`
    position: absolute;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 500;
`;

const Backing = styled(Wrapper)`
    background: rgba(0, 0, 0, 0.75);
`;

const Content = styled(animated.div)`
    background: ${backgroundColor};
    padding: 40px;
    border-radius: ${radius};
    min-width: 300px;

    overflow: hidden;

    border: 1px solid ${inputColor};

    box-shadow: 0px 12px 28px -13px rgba(0, 0, 0, 0.75);

    div {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

const Title = styled.h2`
    margin: 0;
    margin-bottom: 25px;
`;

const Modal = ({ children, title, open, onTryExit, className }) => {
    const background = React.useRef();

    const [backingStyle] = useSpring(
        () => ({
            opacity: open ? 1 : 0,
            pointerEvents: open ? 'all' : 'none',
        }),
        [open]
    );

    const [modalStyle] = useSpring(
        () => ({
            marginTop: open ? '0%' : '200%',
            pointerEvents: open ? 'all' : 'none',
        }),
        [open]
    );

    const onMaybeTryExit = (event) => {
        if (event.target === background.current) {
            if (onTryExit) onTryExit();
        }
    };

    React.useEffect(() => {
        const onEscapeKey = (event) => {
            if (event.keyCode === 27) {
                onTryExit();
            }
        };

        if (open) {
            document.addEventListener('keyup', onEscapeKey);
        } else {
            document.removeEventListener('keyup', onEscapeKey);
        }
    }, [open, onTryExit]);

    return (
        <>
            <Backing style={backingStyle} />
            <Wrapper style={backingStyle} onClick={onMaybeTryExit} ref={background}>
                <Content style={modalStyle} className={className}>
                    {title && <Title>{title}</Title>}
                    {children}
                </Content>
            </Wrapper>
        </>
    );
};

export default Modal;
