import React from 'react';
import styled from '@emotion/styled';
import { inputColor, primaryColor, lightGray } from '../../util/constants';
import Help from './Help';
import Label from './Label';
import Color from 'color';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Input = styled.input`
    outline: none;
    border: none;
    background: none;
    font-size: 16px;
    color: white;
    flex: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    transition: border 0.25s;

    ::placeholder {
        color: ${Color(inputColor).lighten(0.75).string()};
    }
`;

const Textarea = styled.textarea`
    outline: none;
    border: none;
    background: none;
    font-size: 16px;
    color: white;
    flex: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    transition: border 0.25s;

    ::placeholder {
        color: ${Color(inputColor).lighten(0.75).string()};
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;

    background: ${inputColor};
    border-radius: 5px;
    padding: 12px;

    border: solid 1px rgba(0, 0, 0, 0);

    transition: border 0.25s;

    :hover {
        border: solid 1px ${primaryColor};
    }

    ${({ focused }) => focused && `border: solid 1px ${primaryColor};`}
`;

const Unit = styled.span`
    font-weight: bold;
    color: ${lightGray};
`;

const LabeledInput = React.forwardRef(
    ({ label, placeholder, unit, onChange: onChangeProp, value, type, min, max, help, multiline, name }, ref) => {
        const [focused, setFocused] = React.useState(false);

        const onChange = (event) => {
            let newValue = event.target.value;
            if (type === 'number') {
                newValue = parseFloat(newValue);

                if (min && newValue < min) {
                    newValue = value;
                }

                if (max && newValue > max) {
                    newValue = value;
                }

                if (Number.isNaN(newValue)) {
                    newValue = undefined;
                }
            }

            if (onChangeProp) {
                onChangeProp(newValue);
            } else {
                event.target.value = newValue;
            }
        };

        const TextContainer = multiline ? Textarea : Input;

        return (
            <Wrapper>
                <Label>
                    {label}
                    {help && <Help>{help}</Help>}
                </Label>
                <InputWrapper focused={focused}>
                    <TextContainer
                        size='1'
                        name={name || label.toLowerCase()}
                        placeholder={placeholder}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={onChange}
                        value={value}
                        type={type}
                        ref={ref}
                        {...(multiline
                            ? {
                                  rows: 5,
                                  cols: 40,
                              }
                            : {})}
                    />
                    {unit && <Unit>{unit}</Unit>}
                </InputWrapper>
            </Wrapper>
        );
    }
);

export default LabeledInput;
