import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styled from '@emotion/styled';
import { radius, primaryColor, backgroundColor } from '../util/constants';

const Wrapper = styled(animated.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 30px;
    left: 30px;

    box-shadow: 0px 12px 28px -13px rgba(0, 0, 0, 0.75);
    background: ${backgroundColor};
    border: 1px solid ${primaryColor};

    border-radius: ${radius};
    padding: 20px;

    font-weight: bold;
`;

const useToast = () => {
    const [visible, setVisible] = React.useState(false);

    return [
        visible,
        () => {
            setVisible(true);

            setTimeout(() => {
                setVisible(false);
            }, 3000);
        },
    ];
};

const Toast = ({ toast, children }) => {
    const [toastStyle] = useSpring(
        () => ({
            opacity: toast ? 1 : 0,
            bottom: toast ? '30px' : '-60px',
        }),
        [toast]
    );

    return <Wrapper style={toastStyle}>{children}</Wrapper>;
};

export default Toast;
export { useToast };
