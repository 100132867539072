import React from 'react';
import styled from '@emotion/styled';
import { subtleTextColor } from '../util/constants';

const Wrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: -27px;

    z-index: 0;
`;

const Column = styled.div`
    position: absolute;
    height: 100%;
    left: ${({ columnStart, maxPenetration }) => (columnStart / maxPenetration) * 100}%;
`;

const Label = styled.div`
    font-size: 12px;
    color: ${subtleTextColor};
    margin-left: -100%;
    margin-bottom: 10px;
`;

const Separator = styled.div`
    border-left: dashed 1px ${subtleTextColor};
    height: 100%;
`;

const TimelineGrid = ({ maxPenetration }) => {
    const columnSize = (columnsCount, spanning) => {
        let split = spanning / columnsCount;
        const remainder = split % 100;

        if (remainder !== 0) {
            split -= remainder;
        }

        const columns = Array.from(Array(columnsCount), (_, i) => i * split);

        if (remainder !== 0) columns.push(remainder * columnsCount + columnsCount * split);

        if (columns[columns.length - 1] !== maxPenetration) {
            columns.push(maxPenetration);
        }

        return columns;
    };

    return (
        <Wrapper>
            {columnSize(5, maxPenetration).map((columnStart) => (
                <Column columnStart={columnStart} maxPenetration={maxPenetration} key={columnStart}>
                    <Label>{columnStart} ft.</Label>
                    <Separator />
                </Column>
            ))}
        </Wrapper>
    );
};

export default TimelineGrid;
