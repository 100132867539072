import styled from '@emotion/styled';
import LabeledInput from './inputs/LabeledInput';
import LabeledPicker from './inputs/LabeledPicker';

import tanks from '../core/tanks';

const Wrapper = styled.div`
    flex: 1;
`;

const Row = styled.div`
    display: flex;

    div {
        margin-right: 15px;

        :last-of-type {
            margin-right: 0;
        }
    }

    margin-bottom: 15px;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Configuration = ({
    backgasPressure,
    setBackgasPressure,
    backgas,
    setBackgas,
    backgasHoldout,
    setBackgasHoldout,
    stageOverdrop,
    setStageOverdrop,
    sacRate,
    setSacRate,
}) => {
    return (
        <Wrapper>
            <h3>Configuration</h3>

            <Row>
                <LabeledInput
                    type='number'
                    label='Backgas Pressure'
                    placeholder='3600'
                    unit='psi.'
                    onChange={(pressure) => setBackgasPressure(pressure)}
                    value={backgasPressure}
                    min={0}
                    max={4500}
                />
                <LabeledPicker
                    type='number'
                    label='Backgas'
                    help='As sidemount or backmounted doubles.'
                    options={Object.keys(tanks)}
                    onChange={(backgas) => setBackgas(backgas)}
                    value={backgas}
                />
            </Row>

            <Row>
                <LabeledInput
                    type='number'
                    label='Backgas Holdout'
                    help='The amount of backgas to withhold from calculations for wing inflation, drysuit inflation, diluent etc. Applied to both bottles.'
                    placeholder='200'
                    unit='psi.'
                    min={0}
                    max={1000}
                    onChange={(holdout) => setBackgasHoldout(holdout)}
                    value={backgasHoldout}
                />
                <LabeledInput
                    type='number'
                    label='Stage Overdrop'
                    placeholder='33'
                    unit='%'
                    min={0}
                    max={100}
                    help="Percentage to 'back off' from the mathematically proper drop position for each stage. If 3rds (i.e 33%) is used here, this means that backgas and each stage (and backgas) will be used to 66% before being considered 'empty' and requiring another stage."
                    onChange={(overdrop) => setStageOverdrop(overdrop)}
                    value={stageOverdrop}
                />
                <LabeledInput
                    type='number'
                    label='SAC Rate'
                    placeholder='1'
                    unit='cf.'
                    min={0}
                    max={10}
                    help='Bailout surface-air-consumption rate. Generally 1 cf. is a reasonable place to start for bail out purposes. Increase for additional conservancy.'
                    onChange={(sac) => setSacRate(sac)}
                    value={sacRate}
                />
            </Row>
        </Wrapper>
    );
};

export default Configuration;
