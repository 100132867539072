import React from 'react';
import styled from '@emotion/styled';
import Modal from './Modal';
import { lightGray, primaryColor } from '../util/constants';
import Button, { Buttons } from './inputs/Button';

const Header = styled.div`
    background: ${primaryColor};
    margin: -40px;
    height: 200px;
    width: 500px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.span`
    font-weight: bold;
    font-size: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 70px;
        margin-right: 15px;
    }
`;

const WelcomeModal = styled(Modal)`
    max-width: 420px;
`;

const Content = styled.span`
    max-height: 150px;
    display: block;
    overflow: scroll;
`;

const ScrollMessage = styled.span`
    color: ${lightGray};
    font-size: 12px;
    text-align: center;

    width: 100%;
    display: block;
    margin-top: 25px;
`;

const Welcome = () => {
    const agreed = localStorage.getItem('agreed') === 'true';
    const [rejected, setRejected] = React.useState(localStorage.getItem('agreed') === 'false');
    const [visible, setVisible] = React.useState(true);
    const [disclaimerVisible, setDisclaimerVisible] = React.useState(false);

    const [disclaimerScrolled, setDisclaimerScrolled] = React.useState();
    const contentRef = React.useRef();

    const agree = () => {
        setVisible(false);

        setTimeout(() => {
            localStorage.setItem('agreed', true);
        }, 200);
    };

    const reject = () => {
        localStorage.setItem('agreed', false);
        setRejected(true);
    };

    React.useEffect(() => {
        if (contentRef.current) {
            contentRef.current.onscroll = () => {
                if (
                    contentRef.current.scrollTop ===
                    contentRef.current.scrollHeight - contentRef.current.clientHeight
                ) {
                    setDisclaimerScrolled(true);
                }
            };
        }
    }, [contentRef, setDisclaimerScrolled]);

    if (!agreed) {
        return (
            <WelcomeModal open={visible}>
                <Header>
                    <Title>
                        <img src='./soloist.svg' alt='Soloist' />
                        soloist.
                    </Title>
                </Header>

                {rejected ? (
                    <>
                        <h2>Disclaimer Rejected</h2>

                        <span>Sorry, you're required to accept the disclaimer in order to use Soloist.</span>
                    </>
                ) : (
                    <>
                        <h2>{disclaimerVisible ? 'Disclaimer' : 'Welcome'}</h2>

                        <Content ref={contentRef}>
                            {disclaimerVisible
                                ? `
                            Diving in overhead environments is inherently dangerous and requires
                            substantial, special training. Staged dives, CCR's and DPV's require yet
                            additional training. No dive modeling tool can model every possible
                            failure capable of occurring during a dive in an overhead environment.
                            Soloist is a tool for modeling a subset of dive failure scenarios and is
                            not capable of planning dives. All model results should be independently
                            verified outside of Soloist. Modeled dives may be impossible to execute for
                            any number of reasons. Configuration placeholder values are not suggestions and
                            valid values will need to be calculated for any individual diver
                            via personal, empirical data. The author of Soloist is not responsible for any 
                            dive models created with it, the accuracy of any displayed calculations or
                            the results of any dives conducted as a result of using it. By accepting,
                            you acknowledge that the use of this tool is done at your own risk,
                            that you have the training required to validate the results
                            produced.
                        `
                                : `
                            Hey there 👋. Soloist is an advanced bailout modeling tool for complex
                            dives in overhead environments that rely on closed circuit rebreathers
                            (CCR's) and dive propulsion vehicles (DPV's).
                        `}
                        </Content>

                        {disclaimerVisible && <ScrollMessage>Scroll & Read to Agree</ScrollMessage>}

                        <Buttons>
                            {disclaimerVisible ? (
                                <>
                                    <Button primary onClick={agree} disabled={!disclaimerScrolled}>
                                        I Agree
                                    </Button>

                                    <Button onClick={reject}>Reject</Button>
                                </>
                            ) : (
                                <>
                                    <Button primary onClick={() => setDisclaimerVisible(true)}>
                                        Got it
                                    </Button>
                                </>
                            )}
                        </Buttons>
                    </>
                )}
            </WelcomeModal>
        );
    } else {
        return null;
    }
};

export default Welcome;
