import { backgasVolume } from './metrics';
import sliceDiveStages from './sliceDiveStages';

const stageDrops = (
    diveSegments,
    sacRate,
    backgas,
    backgasPressure,
    backgasHoldout,
    overdrop,
    dpvSegments,
    dropMarkers
) => {
    const stages = [];
    const excludingOverdrop = (100 - (overdrop ?? 0)) / 100;

    let currentStage = {
        remaining: backgasVolume(backgas, backgasPressure, backgasHoldout) * excludingOverdrop,
        backgas: true,
    };

    for (let segment of sliceDiveStages(diveSegments, dpvSegments, dropMarkers)) {
        if (!segment.needsStages) continue;

        const atm = segment.realSegment.depth / 33 + 1;
        const rmv = sacRate * atm;

        let segmentRemaining = segment.distance;

        while (segmentRemaining > 0) {
            if (!currentStage) {
                currentStage = {
                    remaining: 80 * excludingOverdrop,
                    segment: segment.realSegment,
                    segmentRemaining: segmentRemaining + segment.offset,
                };
            }

            const availableMinutes = currentStage.remaining / rmv;
            const minutesNeeded = segmentRemaining / segment.exitRate;

            if (availableMinutes - minutesNeeded >= 0) {
                const fractionUsed = minutesNeeded / availableMinutes;
                currentStage.remaining = currentStage.remaining * (1 - fractionUsed);
                segmentRemaining = 0;
            } else {
                const durationCovered = availableMinutes / minutesNeeded;
                segmentRemaining = segmentRemaining * (1 - durationCovered);

                if (!currentStage.backgas) {
                    stages.push({ ...currentStage, remaining: 80 });
                }

                currentStage = null;
            }
        }
    }

    if (currentStage && !currentStage.backgas) {
        stages.push(currentStage);
    }

    return stages;
};

export default stageDrops;
