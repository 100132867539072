import styled from '@emotion/styled';
import React from 'react';
import { useDrag } from 'react-dnd';
import Color from 'color';

import { backgroundColor, radius, DragTypes, primaryColor, inputColor } from '../util/constants';
import SVG from './SVG';

const Wrapper = styled.div`
    flex: 1;
    max-width: 100px;

    display: flex;
    align-items: center;
`;

const Bar = styled.div`
    border-radius: ${radius};
    margin-left: 25px;
    background: ${inputColor};

    padding: 10px;

    @keyframes slidein {
        from {
            margin-left: -100px;
        }
        to {
            margin-left: 0px;
        }
    }
    animation: 0.3s ease-in slidein;
`;

const ToolIcon = styled.div`
    svg {
        width: 75px;
        height: 75px;
        transform: scale(0.8, 0.8) translateY(2px);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${radius};

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    path {
        ${({ disabled }) => disabled && `stroke: ${Color(backgroundColor).lighten(1.2).string()} !important;`}
    }

    ellipse {
        ${({ disabled }) => disabled && `fill: ${Color(backgroundColor).lighten(1.2).string()} !important;`}
        ${({ disabled }) => disabled && `stroke: ${Color(backgroundColor).lighten(1.2).string()} !important;`}
    }

    :hover {
        ${({ disabled }) => !disabled && `background: white;`}

        path {
            ${({ disabled }) => !disabled && `stroke: ${backgroundColor} !important;`}
        }

        ellipse {
            ${({ disabled }) => !disabled && `stroke: ${backgroundColor} !important;`}
            ${({ disabled }) => !disabled && `fill: ${backgroundColor} !important;`}
        }
    }

    transition: all 0.2s;
`;

const ToolWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToolName = styled.div`
    position: absolute;
    left: 125px;

    background: ${backgroundColor};
    border: 1px solid ${primaryColor};
    padding: 15px;
    border-radius: ${radius};
    margin-bottom: 0 !important;
    z-index: 1000;
`;

const Tool = React.forwardRef(({ children, name, disabled, isDragging }, ref) => {
    const [mouseOver, setMouseOver] = React.useState(false);

    return (
        <>
            <ToolWrapper onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
                <ToolIcon ref={ref} disabled={disabled}>
                    {children}
                </ToolIcon>
                {mouseOver && !disabled && !isDragging && <ToolName>{name}</ToolName>}
            </ToolWrapper>
        </>
    );
});

const DiveStageTool = ({ disabled }) => {
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DragTypes.SegmentTool,
            item: {},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <Tool
            ref={dragRef}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            name='Dive Segment'
            disabled={disabled}
            isDragging={isDragging}
        >
            <SVG src='/ccr.svg' />
        </Tool>
    );
};

const DPVTool = ({ disabled }) => {
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DragTypes.DPVTool,
            item: {},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <Tool
            ref={dragRef}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            icon='/dpv.svg'
            name='DPV'
            disabled={disabled}
            isDragging={isDragging}
        >
            <SVG src='/dpv.svg' />
        </Tool>
    );
};

const BailoutCCRMarker = ({ disabled }) => {
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DragTypes.BailoutCCRMarker,
            item: { type: DragTypes.BailoutCCRMarker },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <Tool
            ref={dragRef}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            name='Bailout CCR'
            disabled={disabled}
            isDragging={isDragging}
        >
            <SVG src='/boccr.svg' />
        </Tool>
    );
};

const BailoutDPVMarker = ({ disabled }) => {
    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: DragTypes.BailoutDPVMarker,
            item: { type: DragTypes.BailoutDPVMarker },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <Tool
            ref={dragRef}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            name='Bailout DPV'
            disabled={disabled}
            isDragging={isDragging}
        >
            <SVG src='/bodpv.svg' />
        </Tool>
    );
};

const Toolbar = ({ diveSegments }) => {
    return (
        <Wrapper>
            <Bar>
                <DiveStageTool />
                <DPVTool disabled={diveSegments.length === 0} />
                <BailoutCCRMarker disabled={diveSegments.length === 0} />
                <BailoutDPVMarker disabled={diveSegments.length === 0} />
            </Bar>
        </Wrapper>
    );
};

export default Toolbar;
