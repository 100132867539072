const dropSpring = (small, active) => () => {
    if (!active) return {};

    return {
        from: { transform: 'scale(1, 1)' },
        to: { transform: `scale(${small ? 1.01 : 1.1}, ${small ? 1.01 : 1.1})` },
        config: {
            friction: 0,
            tension: 125,
        },
    };
};

export default dropSpring;
