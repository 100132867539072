import styled from '@emotion/styled';
import { lightGray } from '../../util/constants';

const Label = styled.span`
    color: ${lightGray};
    margin-bottom: 5px;

    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
`;

export default Label;
