import React from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import { useDrop } from 'react-dnd';
import { useSpring, animated } from '@react-spring/web';

import { radius, DragTypes, primaryColor } from '../util/constants';
import dropSpring from '../util/dropSpring';

const Wrapper = styled.div`
    position: relative;
    background: ${primaryColor};
    height: 20px;
    padding: 10px;
    border-radius: ${radius};
    border: solid 1px ${Color(primaryColor).lighten(0.5).string()};

    display: flex;
    align-items: center;

    cursor: pointer;

    top: ${({ depthOffset }) => depthOffset}%;
    width: ${({ distance, penetration }) => `calc(${(distance / penetration) * 100}% - 22px)`};
`;

const Duration = styled.span`
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    white-space: pre;

    margin-left: 10px;

    font-weight: bold;
`;

const Distance = styled.span`
    white-space: pre;
`;

const DropZone = styled(animated.div)`
    height: 100%;
    width: 50px;

    border: dashed 4px white;
    border-radius: ${radius};

    opacity: 0.75;

    z-index: 10;
`;

const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
`;

const DiveSegment = ({
    distance,
    depth,
    exitRate,
    last,
    penetration,
    onDropBefore,
    onDropAfter,
    depthOffset,
    onClick,
    children,
    hideMarkers,
}) => {
    const [{ canDropBefore }, beforeDropZone] = useDrop(
        () => ({
            accept: DragTypes.SegmentTool,
            drop: () => {
                if (onDropBefore) onDropBefore();
            },
            collect: (monitor) => ({
                canDropBefore: !!monitor.canDrop(),
            }),
        }),
        []
    );

    const [{ canDropAfter }, afterDropZone] = useDrop(
        () => ({
            accept: DragTypes.SegmentTool,
            drop: () => {
                if (onDropBefore) onDropAfter();
            },
            collect: (monitor) => ({
                canDropAfter: !!monitor.canDrop(),
            }),
        }),
        []
    );

    const [beforeDropZoneStyle] = useSpring(
        () => ({
            opacity: canDropBefore ? 1 : 0,
            maxWidth: canDropBefore ? '50px' : '0px',
            borderWidth: canDropAfter ? '4px' : '0px',
        }),
        [canDropBefore]
    );

    const [dropAnimation] = useSpring(dropSpring(false, canDropAfter || canDropBefore), [canDropAfter, canDropBefore]);

    const [afterDropZoneStyle] = useSpring(
        () => ({
            opacity: canDropAfter ? 1 : 0,
            maxWidth: canDropAfter ? '50px' : '0px',
            borderWidth: canDropAfter ? '4px' : '0px',
        }),
        [canDropAfter]
    );

    const [markerStyle] = useSpring(
        () => ({
            opacity: canDropAfter || canDropBefore || hideMarkers ? 0 : 1,
        }),
        [canDropAfter, canDropBefore, hideMarkers]
    );

    const duration = Math.ceil(parseFloat(distance) / parseFloat(exitRate));

    return (
        <>
            <DropZone ref={beforeDropZone} style={{ ...beforeDropZoneStyle, ...dropAnimation }} />
            <Wrapper distance={distance} penetration={penetration} depthOffset={depthOffset} onClick={onClick}>
                <animated.div style={markerStyle}>{children}</animated.div>
                <TextWrapper>
                    <Distance>
                        {distance}' @ {depth}'
                    </Distance>
                    <Duration>{duration} min</Duration>
                </TextWrapper>
            </Wrapper>
            {last && <DropZone ref={afterDropZone} style={{ ...afterDropZoneStyle, ...dropAnimation }} />}
        </>
    );
};

export default DiveSegment;
