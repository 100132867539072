import React from 'react';
import styled from '@emotion/styled';

const Label = styled.h3`
    margin-bottom: 19px;
    font-size: 19px;
`;

const Input = styled.input`
    font-size: 19px;

    margin: 0;
    margin-bottom: 19px;
    padding: 0;

    border: none;
    outline: none;
    background: none;

    color: white;
    font-weight: bold;
`;

const EditableLabel = React.forwardRef(({ value, onChange }, ref) => {
    const [editing, setEditing] = React.useState(false);

    const onEdit = () => {
        setEditing(true);
        setTimeout(() => {
            ref.current.select();
        }, 50);
    };

    return editing ? (
        <Input value={value} onDoubleClick={onEdit} onChange={onChange} ref={ref} />
    ) : (
        <Label onDoubleClick={onEdit}>{value}</Label>
    );
});

export default EditableLabel;
