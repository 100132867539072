import React from 'react';
import Color from 'color';
import styled from '@emotion/styled';
import { useSpring } from '@react-spring/web';
import { inputColor } from '../../util/constants';
import Tooltip from '../Tooltip';

const Wrapper = styled.div`
    background: ${Color(inputColor).lighten(1).string()};

    border-radius: 18px;
    width: 18px;
    height: 18px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 5px;
`;

const Help = ({ children }) => {
    const [helpVisible, setHelpVisible] = React.useState(false);
    const [buttonOffset, setButtonOffset] = React.useState();
    const button = React.useRef();

    const [tooltipStyle] = useSpring(
        () => ({
            opacity: helpVisible ? 1 : 0,
            pointerEvents: helpVisible ? 'all' : 'none',
        }),
        [helpVisible]
    );

    React.useEffect(() => {
        if (button.current) {
            setButtonOffset(button.current.offsetLeft);
        }
    }, [button]);

    const close = (event) => {
        document.onclick = undefined;
        setHelpVisible(false);
    };

    const onClick = () => {
        setHelpVisible((visible) => !visible);

        setTimeout(() => {
            document.onclick = close;
        }, 100);
    };

    return (
        <>
            <Tooltip offset={buttonOffset} style={tooltipStyle}>
                {children}
            </Tooltip>

            <Wrapper onClick={onClick} ref={button}>
                ?
            </Wrapper>
        </>
    );
};

export default Help;
