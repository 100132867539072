import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor } from '../util/constants';
import { Logo } from './Header';

const Wrapper = styled.div`
    position: absolute;
    height: 100%;

    background: ${backgroundColor};
    z-index: 10000;
    padding-left: 25px;
    padding-right: 25px;

    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    margin-top: 100px;
`;

const MobileSplash = () => {
    const mobile = window.navigator.platform.match('iPhone|iPod|Android');

    return (
        mobile && (
            <Wrapper>
                <Logo />

                <Content>
                    <h1>👋 Hey</h1>
                    <p>
                        Soloist is a small app, and unfortunately it doesn't currently work well on mobile devices.
                        Soloist relies on a free form timeline layout, something doable on mobile but non trivially.
                        This isn't as high of a priority as the underlying functionality of Soloist itself at the
                        moment.
                    </p>
                    <p>Perhaps one day mobile devices will work, but for now, grab your laptop or tablet.</p>
                </Content>
            </Wrapper>
        )
    );
};

export default MobileSplash;
