import styled from '@emotion/styled';
import Color from 'color';
import { backgroundColor, radius, lightGray } from '../util/constants';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background: ${Color(backgroundColor).lighten(0.5).string()};
    border-radius: ${radius};

    padding: 25px;
`;

const Value = styled.span`
    font-size: 30px;
    font-weight: bold;
`;

const Name = styled.span`
    font-size: 18px;
`;

const Unit = styled.span`
    font-size: 12px;
    font-weight: normal;
    color: ${lightGray};

    margin-left: 5px;
`;

const PlanMetric = ({ name, value, unit }) => {
    return (
        <Wrapper>
            <Name>{name}</Name>
            <Value>
                {value === undefined ? '-' : value}
                {unit && value !== undefined && <Unit>{unit}</Unit>}
            </Value>
        </Wrapper>
    );
};

export default PlanMetric;
