import { FailureModes } from './metrics';
import { DragTypes } from '../util/constants';

const postFailureDpvs = (dpvSegments, dropMarkers, failureMode) => {
    if (failureMode === FailureModes.CCR) return dpvSegments;
    if (dropMarkers[DragTypes.BailoutDPVMarker] === undefined) return [];

    const segments = [...dpvSegments];
    const finalSegments = [];

    let currentSegment = segments.pop();
    const bailoutAt = dropMarkers[DragTypes.BailoutDPVMarker].at;

    while (currentSegment) {
        const currentSegmentEnd = currentSegment.startAt + currentSegment.duration;

        if (bailoutAt >= currentSegmentEnd) {
            finalSegments.push(currentSegment);
        } else if (bailoutAt > currentSegment.startAt && bailoutAt < currentSegmentEnd) {
            finalSegments.push({
                ...currentSegment,
                duration: currentSegment.duration - (currentSegmentEnd - bailoutAt),
            });
        }

        currentSegment = segments.pop();
    }

    finalSegments.reverse();

    return finalSegments;
};

export default postFailureDpvs;
