import React from 'react';
import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Soloist from './components/Soloist';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <Soloist />
        </DndProvider>
    </React.StrictMode>
);
